import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                0244-016360     Ghana-Accra

              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <nav className='navbar'>
        {/* <li> <a href='#footer-subscription' className='btn-mobile'> */}

        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            FASTCLOUD
            <i class='fab fa-typo3' />
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <a className='nav-links' href='#cards'>Services</a>
              {/* <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Services
              </Link> */}
            </li>
            <li className='nav-item'>
              {/* <Link
                to='/services'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Partners
              </Link> */}
              {/* <a className='nav-links' href='#partners'>Partners</a> */}

            </li>
            <li className='nav-item'>
              {/* <Link
                to='/products'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Contact Us
              </Link> */}
              <a className='nav-links' href='#footer-subscription'>Contact Us</a>

            </li>

            <li>
              <Link
                to='/sign-up'
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
                Sign Up
              </Link>
            </li>
          </ul>
          {/* {button && <Button buttonStyle='btn--outline'>SIGN UP</Button>} */}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
